import { MenuProps } from "./types";
import classNames from "./Menu.styles";

export const Menu = ({ children }: MenuProps) => {
  return (
    <ul data-testid='menu' className={classNames.ul}>
      {children}
    </ul>
  );
}

