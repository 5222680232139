import { format, formatRelative } from "date-fns";
import { enGB } from 'date-fns/locale'
import { formatRelativeLocale } from '../../../utils/date'
import { Table, TableBody, TableFooter } from "../../../components/Table";
import { HistoryTableRow } from "./HistoryTableRow";
import { HistoryTableType, SessionsType } from "../types";
import classNames from "./History.styles";

export const HistoryTable = ({ sessionHistory: { day, sessions }, handleCheckbox }: HistoryTableType) => {
  const locale = {
    ...enGB,
    formatRelative: (sessionId: string) => formatRelativeLocale[sessionId]
  };

  return (
    <section className={classNames.table}>
      <header className={classNames.tableHeader}>
        <h6 className={classNames.tableTitle}>{formatRelative(new Date(day), new Date(), { locale })} - {format(new Date(day), 'EEEE, LLLL dd, yyyy')}</h6>
      </header>
      <Table>
        <TableBody>
          {sessions.map((session: SessionsType, id: number) => <HistoryTableRow key={id} session={session} handleCheckbox={handleCheckbox} />)}
        </TableBody>
        <TableFooter>
        </TableFooter>
      </Table>
    </section>
  );
}

