/* eslint-disable import/no-anonymous-default-export */
export default {
    wrapper: "min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8",
    logo: "mx-auto h-12 w-auto",
    title: "mt-6 text-center text-3xl font-extrabold text-gray-900",
    form: "grid gap-4 grid-cols-2",
    half: "col-span-6",
    container: "max-w-md w-full space-y-8",
    label: "block text-sm font-medium text-gray-700",
    input: "mt-1 block w-full py-2 px-3 border bg-white focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md",
    dropDown: "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
    button: "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-400 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  };