/* eslint-disable import/no-anonymous-default-export */
export default {
    wrapper: "flex justify-center flex-col py-10",
    search: "w-7/12 mx-auto mb-8",
    crossIcon: "h-6 w-6 mr-8 text-black",
    trashIcon: "h-5 w-5 mr-3 text-white",
    bulkDelete: "flex flex-row py-2.5 px-5 bg-gray-200 justify-between items-center",
    selected: "flex flex-row",
    table: "mt-4 shadow-[0px_2px_20px_4px_rgba(0,0,0,0.1)] rounded-t-2xl",
    tableCol: "px-2.5 py-5 border-b",
    tableHeader: "border-b-2 border-gray-500 py-7 px-5",
    timeCell: 'text-sm w-36',
    more: 'w-8',
    check: 'w-20',
    customDropdown: 'w-52 shadow-[0px_2px_4px_2px_rgba(0,0,0,0.1)] rounded-lg -left-24 z-10 top-3 py-2 bg-white [&>li]:text-black',
    tableTitle: "font-semibold text-gray-900",
    checkbox: "form-check-input appearance-none h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-customGreen focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer checked:bg-[url('/src/assets/svg/check.svg')]",
};