import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store"
import { loginService, newSessionService, restoreService } from "./services";
import { AuthState } from "./types"

export const initialState: AuthState = {
  loading: false,
  sessionId: '',
  userName: '',
  hasErrors: false
};

export const submitLogin = createAsyncThunk(
  'auth/loginSubmitted',
  async ({ name }: any) => {
    const response = await loginService(name);
    return response;
  }
);

export const submitLogout = createAsyncThunk(
  'auth/logoutSubmitted',
  async () => {
    localStorage.removeItem("sessionId")
    localStorage.removeItem("userName");
    localStorage.removeItem("accessToken");
  }
);

export const startNewSession = createAsyncThunk(
  'auth/logoutSubmitted',
  async () => {
    const response = await newSessionService();
    return response;
  }
);

export const restoreSession = createAsyncThunk(
  'auth/loginSubmitted',
  async () => {
  const sessionId = localStorage.getItem("sessionId");  
    if(sessionId){
      const response = await restoreService({ sessionId }); //after API will be fixed
      return sessionId;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    submitLogout: (state) => {
      state.userName = '';
      state.sessionId = '';
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitLogin.fulfilled, (state, { payload }) => {
        if (payload){
          state.loading = false;
          state.userName = payload.userName;
          state.sessionId = payload.sessionId;
        } else{
          state.hasErrors = true;
        }
      })
      .addCase(submitLogin.rejected, (state) => {
        state.hasErrors = true;
      })
  },
});

export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;