import { useRef, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { selectChatSession, cleanChatSession } from '../chatSessionSlice';
import { selectChatbot } from "../../../features/chat/chatbotSlice";
import { SendMessage } from '../../../components/SendMessage'
import { Message } from './Message';
import classNames from "./Messages.styles";
import { LoadingDots } from '../../../components/LoadingDots';

export const Messages = ({ chatMessages, setMessages } : any) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const { chatSession } = useAppSelector(selectChatSession);
  const { loading } = useAppSelector(selectChatbot);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom()
    return () => {
      dispatch(cleanChatSession());
    }
  }, [chatMessages]);


  useEffect(() => {
    if(Object.keys(chatSession).length){
      setMessages(chatSession);
    }
  }, [chatSession]);

  return (
      <section className={`${classNames.allMessages} ${classNames.activeAllMessages}`}>
          <section className={classNames.wrapper}>
              <section className={classNames.container}>
                <div className={classNames.message}>
                  {chatMessages.map((chat: any, iter: number) => {
                    return (
                      <Message key={iter} chat={chat} />
                    )
                  })}
                  {loading && <div className={classNames.loading}>
                    <LoadingDots />
                  </div>}
                  <div ref={messagesEndRef} />
                </div >
                <SendMessage 
                  messages={chatMessages} 
                  setMessages={setMessages} 
                />
              </section>
          </section>
        
      </section>
  );
}
