import { SearchProps } from "./types";
import { ReactComponent as ZoomIcon } from '../../assets/svg/zoom.svg';
import classNames from "./Search.styles";

export const Search = ({ query, setQuery, className }: SearchProps) => {
  return (
    <div data-testid='search' className={className}>
      <label className={classNames.wrapper}>
          <ZoomIcon data-testid='icon' className={classNames.icon} />
          <input
              type="search"
              className={classNames.input}
              placeholder="Search History"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
          />
      </label>
    </div>
  );
}

