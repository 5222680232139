import axios from "axios";
import { getResponseHeaders } from "../../utils/apiUtils";
import { API_URL } from "../../utils/const";

export const loginService = async (name: string) => {
  const response = await axios.post(`${API_URL}/session/start`, { user: name }, { headers: getResponseHeaders() }); 
  const { session_id, user } = response.data;

  localStorage.setItem("sessionId", session_id);
  localStorage.setItem("userName", user);

  return { sessionId: session_id, userName: user }
};

export const restoreService = async ({ sessionId }: { sessionId: string }) => {
  const response = await axios.get(`${API_URL}/session/${sessionId}`, { headers: getResponseHeaders()  });

  return response.data;
};

export const newSessionService = async () => {
  const  user = localStorage.getItem("userName");
  const response = await axios.post(`${API_URL}/session/start`, { user }, { headers: getResponseHeaders() }); 
  const { session_id } = response.data;

  localStorage.setItem("sessionId", session_id);

  return { sessionId: session_id }
};
