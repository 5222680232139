import { configureStore, Action, combineReducers, PreloadedState } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authReducer from "../features/auth/authSlice";
import chatbotReducer from "../features/chat/chatbotSlice";
import chatSessionReducer from "../features/chat/chatSessionSlice";
import sessionHistoryReducer from "../features/sessionManagement/sessionManagementSlice";

export const store = configureStore({
  reducer: {
    chatBot: chatbotReducer,
    chatSession: chatSessionReducer,
    auth: authReducer,
    sessionHistory: sessionHistoryReducer,
  },
});

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  chatBot: chatbotReducer,
  chatSession: chatSessionReducer,
  auth: authReducer,
  sessionHistory: sessionHistoryReducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;