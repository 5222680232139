import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const key = process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY;

const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${key}`,
    /* ...other configuration options if needed */
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export default appInsights;
