export const manageTokens = () => {

    const  accessToken = localStorage.getItem("accessToken") ?? "";
    const  refreshToken = localStorage.getItem("refreshToken") ?? "";
  
    //if accessToken & refreshToken are not "", the user is logged in - check the token is still valid
    // and renew if necessary
    if (accessToken !== '' && refreshToken !== ''){
  
      const pingURL = process.env.REACT_APP_PING_URL;
      const exp = localStorage.getItem("tokenExpires") ?? new Date().getUTCDate();
      const expiryDate = Number(exp) * 1000;
  
      var now = new Date();
      var utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate(),
        now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        
      // if expired, get refresh token
      if (expiryDate < utc_timestamp) {
        try {
  
          var details: any = {
              "grant_type":"refresh_token",
              "refresh_token":refreshToken,
              "client_id": process.env.REACT_APP_CLIENT_ID,
              "client_secret":process.env.REACT_APP_CLIENT_SECRET
          };
  
          var formBody: any = [];
          for (var property in details) {
              var encodedKey = property;
              var encodedValue = details[property];
              formBody.push(encodedKey + "=" + encodedValue);
          } 
          formBody = formBody.join("&");
  
          fetch("https://" + pingURL + ".shell.com/as/token.oauth2", {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
              },
              body: formBody
          }).then(res => res.json()).then((result) => {
              if(result.access_token){
                const token = result.access_token.split('.')[1];
                const parsedToken = JSON.parse(atob(token));
                localStorage.setItem("accessToken",result.access_token);
                localStorage.setItem("refreshToken",result.refresh_token);
                localStorage.setItem("tokenExpires",parsedToken.exp);
                console.log('Token refreshed successfully');
              }
          });
  
        } catch {
          console.log('Problem refreshing token');
        }
      }
    }
  }

export const checkAccess = () => {
    const accessToken = localStorage.getItem("accessToken") ?? "";
    const refreshToken = localStorage.getItem("refreshToken") ?? "";

    if (accessToken !== '' && refreshToken !== ''){
        const exp = localStorage.getItem("tokenExpires") ?? new Date().getUTCDate();
        const expiryDate = Number(exp) * 1000;

        var now = new Date();
        var utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate(),
        now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        
        // if expired, user doesn't have access
        if (expiryDate < utc_timestamp) {
            return false;
        } else {
            return true;
        }
    }
    
    return false;
}