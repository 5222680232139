import { useForm, SubmitHandler } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getChatBot, selectChatbot } from "../../features/chat/chatbotSlice";
import { selectAuth } from "../../features/auth/authSlice";
import { SendMessageProps, TextType } from "./types";
import classNames from "./SendMessage.styles";
import appInsights from "../../utils/appInsights";

export const SendMessage = ({ messages, setMessages }: SendMessageProps) => {
  const dispatch = useAppDispatch();
  const { userName } = useAppSelector(selectAuth);
  const { loading } = useAppSelector(selectChatbot);
  const { register, handleSubmit, reset } = useForm<TextType>();
  let isKeyDown = false;
  
  const resizeTextArea = () => {
    if (!isKeyDown){
      const textArea = document.getElementById("text-area") as HTMLTextAreaElement;
      if (textArea){
        var lineBreak = /\r|\n/.exec(textArea.value);
        if (textArea.value.length < 96 && !lineBreak) {
          textArea.style.height = '40px';
        } else {
          textArea.style.height = '200px';
        }
      }
    }
  }

  const countWords = (text: string) => {
    // Remove leading/trailing spaces and split the text into words
    const words = text.trim().split(/\s+/);
    // Return the number of words
    return words.length;
  };

  const onSubmit: SubmitHandler<TextType> = async data => {
    isKeyDown = false;

    setMessages([...messages, { id: uuidv4(), name: userName, own: true, message: data.message }]);

    try{
        const chatAI = await dispatch(getChatBot(data.message));
        setMessages((prevSelected: any) => [...prevSelected, { id: chatAI.payload?.id, name: chatAI.payload ? 'Shell-e' : "System", own: false, notFromSessionLoad: true, message: chatAI.payload ? chatAI.payload?.response : "An unexpected exception occurred, bot AI was unable to generate a response. Your chat is not recorded" }]);
    }catch(err){} 
    reset();
    resizeTextArea();
    
    const wordsSent = countWords(data.message);
    dispatch(() => {
      appInsights.trackEvent({ 
        name: 'Question Sent', 
        properties: { buttonId: 'submitButton' }, 
        measurements: {
          'words in question': wordsSent
        }
      })
    });
  };

  const checkEnterSubmit = (e: any) => {
    isKeyDown = true;
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      e.target.form.requestSubmit();
    } else {
      // when pasting, this ensures the content has been captured first
      setTimeout(() => {
        isKeyDown = false;
        resizeTextArea();
      }, 500);
    }
  };


  return (
      <>
          <form onSubmit={handleSubmit(onSubmit)} className={classNames.form}>
            <div className="textarea-container">
              <textarea id='text-area' disabled={loading} placeholder='Enter your message' className={classNames.input} 
                autoComplete="off" {...register("message", { required: true })}
                onKeyDown={checkEnterSubmit}
                onBlur={resizeTextArea}
                onFocus={resizeTextArea} />
            </div>
            <button disabled={loading} type="submit" className={classNames.button}>
              Send
            </button>
          </form>
      </>
  );
}

