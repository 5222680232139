import { useState } from "react";
import { Link } from "react-router-dom";
import { Header } from "../Header";
import { Logo } from "../Logo";
import { Menu, MenuItem } from "../Menu";
import { CustomDropdown } from "../CustomDropdown";
import { ReactComponent as UserIcon } from '../../assets/svg/user.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/svg/arrowDown.svg';
import { PageLayoutProps } from "./types";
import classNames from "./PageLayout.styles";

export const PageLayout = ({ children }: PageLayoutProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Header>
        <Link to="/">
          <Logo size="sm" />
        </Link>
        <section className={classNames.menu}>
          <Menu>
            <MenuItem to="/">Home</MenuItem>
            <MenuItem to="/session-management">History</MenuItem>
            <MenuItem to="/archive">Archive</MenuItem>
            <MenuItem to="/help">Help</MenuItem>
          </Menu>
        </section>
        <CustomDropdown
          open={open}
          trigger={
            <section onClick={handleOpen} className={classNames.wrapper}>
              <UserIcon className={classNames.user} />
              <section className={classNames.select}>
                <h6 className={classNames.title}>Joe Bloggs</h6>
                <a className={classNames.email}>joe.bloggs@shell.com</a>
              </section>
              <ArrowDownIcon className={classNames.arrow} />
            </section>
          }
          menu={[
            <Link to="/">Logout</Link>
          ]}
        />
      </Header>
      <section data-testid='pageLayout' className={classNames.content}>
        {children}
      </section>
    </>
  );
}

