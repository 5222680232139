import { HeaderProps } from "./types";
import classNames from "./Header.styles";

export const Header = ({ children }: HeaderProps) => {
  return (
    <header data-testid='header' className={classNames.wrapper}>
      {children}
    </header>
  );
}

