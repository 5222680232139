import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { CustomDropdown } from "../../../components/CustomDropdown";
import { TableCol, TableRow, TableDots } from "../../../components/Table";
import classNames from "./History.styles";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { HistoryTableRowType } from "../types";
import { useAppDispatch } from "../../../redux/store";
import { getChatSession } from "../../chat/chatSessionSlice";
import appInsights from "../../../utils/appInsights";

export const HistoryTableRow = ({ session, handleCheckbox }: HistoryTableRowType) => {
  const { last_time_stamp, last_query, selected, _id } = session;
  const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClick(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOpen = () => {
    setIsComponentVisible(true);
  };

  const handleViewSession = () => {
    dispatch(getChatSession(_id));
    appInsights.trackEvent({ name: 'Restore Old Session', properties: { buttonId: 'viewSessionButton' } });
    navigate('/chat');
  }

  return (
    <TableRow key={_id}>
      <TableCol className={`${classNames.tableCol} ${classNames.check}`}>
        <div className="form-check">
          <input 
            className={classNames.checkbox} 
            type="checkbox" 
            checked={selected}
            onChange={(event) => handleCheckbox(event, _id)}
          />
        </div>
      </TableCol>
      <TableCol className={`${classNames.tableCol} ${classNames.timeCell}`}>{format(new Date(last_time_stamp), 'HH:mm aaa')}</TableCol>
      <TableCol className={classNames.tableCol}>{last_query}</TableCol>
      <TableCol className={`${classNames.tableCol} ${classNames.more}`}>
        <div className="relative" ref={ref as any}>
          <TableDots onClick={handleOpen}><ul></ul></TableDots>
          {isComponentVisible && 
            <CustomDropdown
              open={isComponentVisible}
              className={classNames.customDropdown}
              menu={[
                <button onClick={handleViewSession}>View Session</button>,
                <Link style={{textDecoration: 'none', pointerEvents: 'none', color: 'rgb(156 163 175)'}} to="/">Export Session</Link>,
                <Link style={{textDecoration: 'none', pointerEvents: 'none', color: 'rgb(156 163 175)'}} to="/">More Related Request</Link>,
                <Link style={{textDecoration: 'none', pointerEvents: 'none', color: 'rgb(156 163 175)'}} to="/">Remove From History</Link>,
              ]}
            />
          }
        </div>
      </TableCol>
  </TableRow>
  );
}
