import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { useAppDispatch } from "../../redux/store";
import { submitLogin } from "../auth/authSlice";
import { LoginFormValues } from "../auth/types"
import classNames from "../auth/login/Login.styles";

export const ChatStartPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<LoginFormValues>();

  const onSubmit: SubmitHandler<LoginFormValues> = data => {
    dispatch(submitLogin(data));
    navigate('/chat');
  };

  return (
    <section data-testid="login" className={classNames.wrapper}>
      <div className={classNames.container}>
        <div>
          <div className={classNames.logo}>
            <Logo />
          </div>
          <h2 className={classNames.title}>Shell-e Chat</h2>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className={classNames.form}> 
          <div className={classNames.half}>
            <label className={classNames.label}>Name</label>
            <input className={`banner ${errors?.name ? "border-red-600" : "border-gray-300"} ${classNames.input}`} {...register("name", { required: true })} />
          </div>
          <div className={classNames.half}>
            <button disabled={isSubmitting} type="submit" className={classNames.button}>
              Start
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

