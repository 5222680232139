import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store"
import { AuthState } from "../auth/types"
import { API_URL } from "../../utils/const"
import { InitialStateChatbotType } from "./types";
import { getResponseHeaders } from "../../utils/apiUtils";

export const initialState: InitialStateChatbotType = {
  hasErrors: false,
  loading: false,
  chatbot: {},
  messages: []
};

export const getChatBot = createAsyncThunk(
  'chatbot/chat',
  async (message: string, { getState }) => {
    const { auth } = getState() as { auth: AuthState };
    const sessionId = localStorage.getItem("sessionId");  
    const userName = localStorage.getItem("userName");  
    const res =  await axios.post(`${API_URL}/chat`,
      {
        "query": message,
        "session_id": sessionId,
        "user": userName
      },
      { headers: getResponseHeaders() }
    );
    
    return { id: uuidv4(), ...res.data, selected: false }
  }
);

export const chatbotSlice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    chooseSuggestion: (state, { payload }) => {
      state.chatbot.selected = payload
    },
    chooseResponse: (state, { payload }) => {
      state.messages = state.messages.map((item: any) => item.id === payload ? { ...item, selected: true } : { ...item, selected: false })
    },
  },
  extraReducers:
    (builder) => {
      builder
        .addCase(getChatBot.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.messages = [
            ...state.messages, 
            {
              id: payload.id,
              response: payload.response,
              selected: payload.selected,
              suggestions: [
              ],
            }
          ]; 
          state.hasErrors = false;
        })
        .addCase(getChatBot.pending, (state) => {
          state.loading = true;
        })
        .addCase(getChatBot.rejected, (state) => {
          state.hasErrors = true;
          state.loading = false;
        })
    },
});

const { actions } = chatbotSlice;
export const { chooseSuggestion, chooseResponse } = actions;
export const selectChatbot = (state: RootState) => state.chatBot;
export const selectActiveResponse = (state: RootState) => state.chatBot.messages.find((item: { selected: boolean }) => item.selected);
export default chatbotSlice.reducer;