import { ReactComponent as LoadingDotsIcon } from '../../assets/svg/loadingdots.svg';
import { useRef, useEffect } from 'react';

export const LoadingDots = () => {
  
  const scrollToBottom = () => {
    let itemToScroll = document.getElementById("loadingDots")?.parentElement;
    if (itemToScroll) {
      itemToScroll.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  };

  useEffect(() => {
    setTimeout(scrollToBottom , 500);
  });

  return (
    <div id="loadingDots">
      <LoadingDotsIcon data-testid='loading-dots'/>
    </div>
  );
}

