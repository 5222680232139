import React from "react";
import { TableProps } from "./types";

export const TableRow = ({ children }: TableProps) => {
  return (
    <tr>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child);
        }
      })}
    </tr>
  );
};
