export const getResponseHeaders = () => {

  //only passes accessToken if we are using PingID
  if(process.env.REACT_APP_USE_PINGID === 'false'){
    return { 
      "x-api-key": "VirtualSME"
    }
  }

  const  accessToken = localStorage.getItem("accessToken") ?? "";
  const headers = { 
    "x-api-key": "VirtualSME",
    "x-access-token": accessToken
  }
  return headers;
}