import { memo } from "react";
import { TableColProps } from "./types";

const defaultProps: Partial<TableColProps> = {
  align: "left",
};

export const TableCol = memo(
  ({ children, className, tag = "td", ...props }: TableColProps) => {
    const componentProps: Omit<TableColProps, "children"> = {
      ...defaultProps,
      ...props,
    };
    if (tag === "td") {
      return <td className={className} {...componentProps}>{children}</td>;
    } else {
      return <th className={className} {...componentProps}>{children}</th>;
    }
  }
);
