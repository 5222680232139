/* eslint-disable no-loop-func */
import { MessageComponentType } from "../types";
import classNames from "./Messages.styles";
import { useState, useEffect } from "react";

export const Message = ({ chat } : MessageComponentType) => {
  const [openAIPIResponse, setOpenAIPIResponse] = useState('');
  const [delay, setDelay] = useState(70);

  const scrollToBottom = () => {
    let itemToScroll = document.getElementById(chat.id);
    if (itemToScroll) {
      itemToScroll.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }

  useEffect(() => {
    async function fetchData() {
      const caretSymbol = "█";
      let escapedResponse = chat.message.replace(/\r?\n|\r/g, '<br />');
      escapedResponse = escapedResponse.replaceAll(caretSymbol, '');

      // code block formatting
      if (escapedResponse.indexOf("```") >- 1){
        escapedResponse = escapedResponse.replace(/```/, "<pre class=code>").replace(/```/, "</pre>");
      }

      // inline code (if there are any single `'s left after the above replace then...)
      while (escapedResponse.indexOf(" `") >- 1 && escapedResponse.indexOf("` ") >- 1){
        escapedResponse = escapedResponse.replace(/ `/, "<span class=inlineCode>").replace(/` /, "</span>");
      }


      if (chat.own || !chat.notFromSessionLoad) {
        setOpenAIPIResponse(escapedResponse);
        scrollToBottom();
      } else {
        const words = escapedResponse.split(' ');
        let output = '';

        for (let i = 0; i < words.length; i++) {
          setTimeout(() => {
            const spaceOrNoSpace = (i == words.length-1) ? "" : " ";
            output += `${words[i]}${spaceOrNoSpace}`;
            setOpenAIPIResponse(output + caretSymbol);
            scrollToBottom();

            //remove the caret
            setTimeout(() => setOpenAIPIResponse(output), delay + 1000);
          }, delay * i);
        }

        
      }
    }
    fetchData();
  }, [delay]);


  return (
    <section id={chat.id}
      key={chat.id} 
      className={`${classNames.wrapperMessage} ${chat.name === "System" ? "pointer-events-none" : "" } ${chat.own  ? `${classNames.wrapperOwnMessage}` : `${classNames.wrapperAiMessage}`}`}
    >
      <span className={`${classNames.name} ${chat.own ? `${classNames.ownName}` : `${classNames.aiName}`}`}>{chat.name}</span>
      <div className={`${classNames.singleMessage} ${(chat.own || chat.name === "System") ? `${classNames.ownMessage}` : ` ${classNames.aiMessage}`} ${chat.name === "System" ? " bg-customGray" : "" }`}><div id="text-write-area" dangerouslySetInnerHTML={{__html: openAIPIResponse}} /></div>
    </section>
  );
}
