/* eslint-disable import/no-anonymous-default-export */
export default {
    menu: 'mr-auto ml-14 flex items-center',
    content: 'max-w-screen-lg mx-auto',
    email: 'text-sm font-avenir text-gray-400',
    title: 'text-sm text-black font-avenir font-semibold',
    wrapper: 'flex items-center',
    user: 'rounded-full mr-2',
    arrow: 'ml-2',
    select: 'flex flex-col',
};