import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/const";
import { RootState } from "../../redux/store"
import { SessionHistoryType, SessionsType, InitialStateType } from "./types";
import { getResponseHeaders } from "../../utils/apiUtils";

export const initialState: InitialStateType = {
  hasErrors: false,
  sessionHistory: [],
  totalCount: 0
};

export const getSessionHistory = createAsyncThunk(
  'sessionHistory/summary',
  async () => {
    const tempAuth = localStorage.getItem("userName");
    const res = await axios.post(`${API_URL}/user/summary`,
      {"user": tempAuth, "skip": 0, "limit": 100},
      { headers: getResponseHeaders()  }
    );

    return res.data
  }
);

export const sessionHistorySlice = createSlice({
  name: "sessionHistory",
  initialState,
  reducers: {
    chooseSessionHistory: (state, { payload }) => {
      state.sessionHistory = state.sessionHistory.map((sessionHistory: SessionHistoryType) => ({ ...sessionHistory, sessions: sessionHistory.sessions.map((session: SessionsType) => session._id === payload.summary ? { ...session, selected: !session.selected } : session ) }))
    }, 
  },
  extraReducers:
    (builder) => {
      builder
        .addCase(getSessionHistory.fulfilled, (state, { payload }) => {
          state.sessionHistory = Object.entries(payload.summary).map(([day, sessions]: any) => ({ day, sessions: sessions.map((session: SessionsType) => ({ ...session, selected: false }))}));
          state.totalCount = payload.total_count;
          state.hasErrors = false;
        })
        .addCase(getSessionHistory.rejected, (state) => {
          state.hasErrors = true;
        })
    },
});

const { actions } = sessionHistorySlice;
export const { chooseSessionHistory } = actions;
export const selectSession = (state: RootState) => state.sessionHistory;
export default sessionHistorySlice.reducer;