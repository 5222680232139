import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../redux/store";
import { Messages } from './messages/Messages';
import { submitLogout, startNewSession } from "../../features/auth/authSlice";
import { cleanChatSession } from "./chatSessionSlice";
import { MessageType } from "./types";
import classNames from "./Chat.styles";
import { Logo } from '../../components/Logo';
import { checkAccess } from '../../components/Authentication/tokenManagement';
import appInsights from '../../utils/appInsights';

export const Chat = () => {
  const [chatMessages, setMessages] = useState<MessageType[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(submitLogout());
    dispatch(cleanChatSession());
    navigate('/');
  };

  const handleStartNewSession = () => {
    dispatch(startNewSession());
    dispatch(cleanChatSession());
    setMessages([]);
    appInsights.trackEvent({ name: 'Start New Session Clicked', properties: { buttonId: 'StartNewSessionButton' } });
  };

  // check if logged in and throw user out if not
  useEffect(() => {
    if (process.env.REACT_APP_USE_PINGID === 'true' ){
      const hasAccess = checkAccess();
      if (!hasAccess) { 
        navigate('/logout');
      }
    }
    appInsights.trackPageView({ name: 'Chat Page'});
  });

  return (
    <>
      <header className={classNames.header}>
        <div className={classNames.container}>
          <button className={classNames.link} onClick={handleStartNewSession}>Start new session</button>
          <Link className={classNames.link} to="/session-management">Session Management</Link>
          {process.env.REACT_APP_USE_PINGID === 'true' ?
            <Link className={classNames.link} to="/logout">Logout</Link>
          :  <button className={classNames.link} onClick={handleLogout}>Logout</button>
          }
        </div>
      </header>
      <section className={`${classNames.wrapper} ${'justify-center'}`}>  
        <Messages chatMessages={chatMessages} setMessages={setMessages}/>
      </section>
      
      <div className={classNames.footer}>
        <div className={classNames.logo}>
          &nbsp; <Logo /> Copyright ©2023
        </div>
      </div>
    </>
  );
}

