import classNames from "./CustomDropdown.styles";

export const CustomDropdown = ({ open, menu, className}: any) => {
  return (
    <div data-testid='customDropdown'>
      {open ? (
        <ul className={`${className ? className: ''} ${classNames.select}`}>
          {menu.map((menuItem: any, index: number) => (
            <li key={index} className={classNames.option}>{menuItem}</li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}

