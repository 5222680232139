import { useEffect } from "react";
import { Link } from "react-router-dom";
import appInsights from "../../utils/appInsights";
import { Logo } from "../Logo";
import classNames from "./Landing.styles";


export const LandingPage = () => {

  useEffect(() => {
    appInsights.trackPageView({ name: 'Landing Page'});
  });
  
  const handleButtonClick = () => {
    appInsights.trackEvent({ name: 'Login Button Clicked', properties: { buttonId: 'loginButton' } });
  };

  return (
    <section data-testid="landing" className={classNames.wrapper}>
      <div className={classNames.container}>
        <div>
          <div className={classNames.logo}>
            <Logo />
          </div>
          <h2 className={classNames.title}>Shell-e Chat</h2>
        </div>
        <section className={classNames.form}> 
          <div className={classNames.half}>
            <Link onClick={handleButtonClick} className={classNames.button} to="/login">Login</Link>
          </div>
        </section>
      </div>
    </section>
  );
}
