/* eslint-disable import/no-anonymous-default-export */
export default {
    wrapper: 'w-full border border-gray-300 bg-white rounded-md shadow-sm h-full',
    form: 'w-full flex h-12',
    button: 'w-1/5 rounded-r-md group relative w-full flex justify-center items-center py-2 px-4 border border-transparent text-white bg-yellow-400 hover:bg-red-600 focus:outline-none sm:text-sm',
    container: 'p-4 flex flex-col justify-between h-full',
    input: 'w-4/5border-gray-300 rounded-l-md block w-full py-1 px-3 border bg-white block w-full shadow-sm sm:text-sm',
    message: 'w-full flex h-[calc(100%_-_4rem)] flex-col content-start overflow-y-auto',
    name: 'text-sm text-xs',
    ownMessage: 'rounded-tl-none text-customShellGreyDark bg-customShellGrey mt-2',
    aiMessage: 'rounded-large text-customShellGreyDark rounded-tl-none mt-2 bg-customShellYellow from-customYellow via-customOrange to-customRose',
    ownName: 'pl-2',
    aiName: 'pl-2',
    active: 'rounded-large text-customShellGreyDark rounded-tl-none mb-2 bg-customShellYellow from-customOrangeBlue via-customRoseBlue to-customPinkBlue',
    singleMessage: 'px-3 py-4 rounded-lg text-sm',
    wrapperMessage: 'mb-4 mt-0',
    wrapperAiMessage: 'flex flex-col cursor-pointer',
    loading: 'max-w-lg mb-2 mr-2 flex flex-col text-right cursor-pointer mb-4',
    wrapperOwnMessage: 'ml-0 pointer-events-none',
    allMessages: 'h-full flex items-center',
    activeAllMessages: 'lg:w-full md:w-full sm:w-full xl:w-3/4 2xl:w-2/3'
};
