import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { selectSession, getSessionHistory, chooseSessionHistory } from '../sessionManagementSlice';
import { TrashIcon, XIcon } from '@heroicons/react/outline'
import { Button } from "../../../components/Button";
import { PageLayout } from "../../../components/PageLayout";
import { Search } from "../../../components/Search";
import { HistoryTable } from './HistoryTable';
import { SessionHistoryType, SessionsType } from "../types";
import classNames from "./History.styles";
import appInsights from "../../../utils/appInsights";

export const History = () => {
  const [query, setQuery] = useState("");
  const { sessionHistory } = useAppSelector(selectSession);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSessionHistory());
    appInsights.trackPageView({ name: 'Session Management Page'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const handleBulkRemove = () => {};

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    dispatch(chooseSessionHistory(id));
  };
  
  const someSelected = sessionHistory.map((history: SessionHistoryType) => history.sessions.filter((session: SessionsType) => session.selected)).flatMap((session: SessionsType) => session);

  return (
    <PageLayout>
      <section data-testid="history" className={classNames.wrapper}>
        <>
          <Search className={classNames.search} query={query} setQuery={setQuery} />
          {someSelected.length ? 
            <section className={classNames.bulkDelete}>
              <div className={classNames.selected}>
                <XIcon className={classNames.crossIcon} />
                <p>{someSelected.length} selected</p>
              </div>
              <Button title="Delete" icon={<TrashIcon className={classNames.trashIcon} />} onClick={handleBulkRemove} />
            </section>
          : null}
          {sessionHistory.map((history: SessionHistoryType, iter: number) => <HistoryTable key={iter} sessionHistory={history} handleCheckbox={handleCheckbox} />)}
        </>
      </section>
    </PageLayout>
  );
}

