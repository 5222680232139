import { useEffect } from "react";
import { withRouter } from "../../hooks/withRouter"; 
import appInsights from "../../utils/appInsights";
import { Logo } from "../Logo";
import classNames from "./Authentication.styles";

const Logout = () => {
    useEffect(() => {
        window.localStorage.clear();
        window.location.href = "/";
        appInsights.trackPageView({ name: 'Logout Page'});
    }, [])
    
    return (
        <section data-testid="landing" className={classNames.wrapper}>
            <div className={classNames.container}>
                <div>
                    <div className={classNames.logo}>
                        <Logo />
                    </div>
                    <h2 className={classNames.title}>Logging out...</h2>
                </div>
            </div>
        </section>
    ); 
}

export default withRouter(Logout);