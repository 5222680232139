import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from "./redux/store";
import { App } from './components/App';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

interface authConfigType {
  issuer: string;
  clientId: string;
  redirectUrl: string; 
 }
 
 const authConfig: authConfigType = {
  issuer: process.env.REACT_APP_ISSUER!,
  clientId: process.env.REACT_APP_CLIENTID!,
  redirectUrl: process.env.REACT_APP_REDIRECTURL!,
 }

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);