import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Chat } from "../../features/chat"
import { restoreSession } from "../../features/auth"
import { NotFound } from "../NotFound"
import { History } from "../../features/sessionManagement/History/History";
import Login from "../Authentication/Login";
import Logout from "../Authentication/Logout";
import { LandingPage } from "../Landing";
import { useEffect } from "react";
import { useAppDispatch } from "../../redux/store";
import { getChatSession } from "../../features/chat/chatSessionSlice";
import { ChatStartPage } from "../../features/chat/ChatStartPage";
import { manageTokens } from "../Authentication/tokenManagement";


export const App = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {

    setInterval(manageTokens, 60000);

    const userName = localStorage.getItem("userName");
    if(process.env.REACT_APP_USE_PINGID === 'false'){
      const login = async () => {
        if(location.pathname === "/") {
          localStorage.removeItem("sessionId");
          localStorage.removeItem("userName");
          localStorage.removeItem("accessToken");
        }else{
          if(userName){
            navigate(location.pathname);
            if(location.pathname === "/chat") {
              const token = localStorage.getItem("sessionId");  
              dispatch(getChatSession(token));
            }
          }else{
            navigate('/');
          }
        }
      };
      
      login();
    } else {
      const restore = async () => {
          if(userName){
            restoreSession()
            navigate(location.pathname);
            if(location.pathname === "/chat") {
              const token = localStorage.getItem("sessionId");  
              dispatch(getChatSession(token));
            }
          }
      };
      restore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
      <Routes>
        {process.env.REACT_APP_USE_PINGID === 'true' 
          ?
          <>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/auth/callback" element={<Login />} />
          </>
          :  <Route path="/" element={<ChatStartPage />} />
        }
        <Route path="/chat" element={<Chat />} />
        <Route path="/session-management" element={<History />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
  );
}