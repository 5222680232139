import { NavLink } from "react-router-dom";
import { MenuItemProps } from "./types";
import classNames from "./Menu.styles";

export const MenuItem = ({ children, to }: MenuItemProps) => {
  return (
    <li className={classNames.li}>
      <NavLink 
        to={to}        
        className={({ isActive }) =>
          isActive ? classNames.active : ''
        }>
          {children}
      </NavLink>
    </li>
  );
}

