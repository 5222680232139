import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { API_URL } from "../../utils/const";
import { InitialStateSessionType } from "./types";
import { getResponseHeaders } from "../../utils/apiUtils";

export const initialState: InitialStateSessionType = {
  hasErrors: false,
  loading: false,
  chatSession: [],
};

export const getChatSession = createAsyncThunk(
  'chatSession/chat',
  async (id: any) => {
    const res = await axios.get(`${API_URL}/session/${id}`, { headers: getResponseHeaders() });
    return res.data;
  }
);

export const chatSessionSlice = createSlice({
  name: "chatSession",
  initialState,
  reducers: {
    cleanChatSession: (state) => {
      state.chatSession = []
    },
  },
  extraReducers:
    (builder) => {
      builder
        .addCase(getChatSession.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.chatSession = payload.conversations?.map((item: any) => ({
            id: item._id,
            message: item.message,
            name: item.actor_name==='AI' ? 'Shell-e' : item.actor_name,
            own: item.is_user,
            facts: item.retrieved_facts,
          }));
          state.hasErrors = false;
        })
        .addCase(getChatSession.pending, (state) => {
          state.loading = true;
        })
        .addCase(getChatSession.rejected, (state) => {
          state.hasErrors = true;
          state.loading = false;
        })
    },
});

const { actions } = chatSessionSlice;
export const { cleanChatSession } = actions;
export const selectChatSession = (state: RootState) => state.chatSession;
export default chatSessionSlice.reducer;