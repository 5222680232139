import React from "react";
import { TableProps } from "./types";

export const TableBody = ({ children }: TableProps) => {
    return (
      <tbody>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child);
          }
        })}
      </tbody>
    );
};
