import { TableProps } from "./types";
import classNames from "./Table.styles";

export const TableDots = ({ children, onClick }: TableProps) => {
  return (
    <div onClick={onClick}>
      <div className={classNames.dotsIcon}>...</div>
      <div className={classNames.dotsAction}>
        {children}
      </div>
    </div>
  )
};
