import { ReactComponent as LogoIcon } from '../../assets/svg/logo.svg';
import { SizeMetricProps } from '../../utils/type';
import { LogoProps } from './types';
import classNames from "./Logo.styles";

export const Logo = ({ size = 'md' }: LogoProps) => {
  const sizeMetric: SizeMetricProps = {
    sm: 0.8,
    md: 1,
    lg: 1.5,
    xl: 2,
  }

  return (
    <div className={`${classNames.wrapper} ${size === "md" ? 'h-12' : 'h-10'}`}>
      <LogoIcon data-testid='logo' className={classNames.logo} />
    </div>

  );
}

