import { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { submitLogin } from "../../features/auth/authSlice";
import { ChatStartPage } from "../../features/chat/ChatStartPage";
import { withRouter } from "../../hooks/withRouter"; 
import { useAppDispatch } from "../../redux/store";
import appInsights from "../../utils/appInsights";
import { App } from '../App';
import classNames from "./Login.styles";

var base64 = require('base64-js');
var HAS_CRYPTO = typeof window !== 'undefined' && !!window.crypto; 

const Login = () => {    
    const [username, setUsername] = useState<any>("");
    const [pingURL, setPingURL] = useState(process.env.REACT_APP_PING_URL);
    const [clientId, setClientId] = useState(process.env.REACT_APP_CLIENT_ID);
    const [isLoginSuccess, setIsLoginSuccess] = useState(false);
    const [showLoginError, setShowLoginError] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        appInsights.trackPageView({ name: 'Login Page'});
    });
    
    var codeVerifier: any, code_challenge: any;

    const getURLParameter = ( name: any, url: any ) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
        const regexS = "[\\?&]"+name+"=([^&#]*)";
        const regex = new RegExp(regexS);
        const results = regex.exec(url);
        return results == null ? null : results[1];
    }


    const getToken = () => {
        var state = getURLParameter("code",window.location.href); 
        var details: any = {
            "client_id": clientId,
            "grant_type":"authorization_code",
            "redirect_uri":process.env.REACT_APP_SSO_APP_REDIRECT_URL,
            "code_verifier": window.localStorage.getItem("verifier"),
            "code":state,
            "client_secret":process.env.REACT_APP_CLIENT_SECRET
        };

        var formBody: any = [];

        for (var property in details) {
            var encodedKey = property;
            var encodedValue = details[property];
            formBody.push(encodedKey + "=" + encodedValue);
        } 
        
        formBody = formBody.join("&");

        fetch("https://" + pingURL + ".shell.com/as/token.oauth2", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
        }).then(res => res.json()).then((result) => {
            if(result.access_token){
                const token = result.access_token.split('.')[1];
                const parsedToken = JSON.parse(atob(token));

                let memberExistsInGroup = false;
                const allowedGroup = process.env.REACT_APP_CLIENT_GROUP;
                if(parsedToken.isMemberOf && 
                    (parsedToken.isMemberOf === allowedGroup || parsedToken.isMemberOf.indexOf(allowedGroup) > -1)){
                    memberExistsInGroup = true;
                }

                if(memberExistsInGroup && parsedToken.mail){
                    const email = parsedToken.mail;
                    localStorage.setItem("email",email); 
                    localStorage.setItem("accessToken",result.access_token);
                    localStorage.setItem("refreshToken",result.refresh_token);
                    localStorage.setItem("tokenExpires",parsedToken.exp);
                    const givenName = parsedToken.givenName;
                    const sn = parsedToken.sn;
                    localStorage.setItem("displayName",givenName +" "+ sn); 
                    setUsername(email);
                    setIsLoginSuccess(true);
                    setShowLoginError(false);

                    //TODO: test this fully, as we need to make sure the name comes through on the front end
                    //      can only do that once the backend is working again.
                    // dispatch(submitLogin(givenName));
                    // navigate('/chat');
                }else{
                    setShowLoginError(true);
                }
            }
        });
    }

    const bufferToString = (buffer: any) => {
        const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let state = [];
        for (var i = 0; i < buffer.byteLength; i += 1) {
            const index = buffer[i] % CHARSET.length;
            state.push(CHARSET[index]);
        }
        return state.join('');
    }
    
    const generateRandom = (size: any) => {
        const buffer = new Uint8Array(size);
        if (HAS_CRYPTO) {
            window.crypto.getRandomValues(buffer);
        }
        else {
            for (var i = 0; i < size; i += 1) {
                buffer[i] = Math.random();
            }
        }
        return bufferToString(buffer);
    }

    const textEncodeLite = (str: any) => {
        const buf = new ArrayBuffer(str.length);
        let bufView = new Uint8Array(buf);
        for (var i = 0; i < str.length; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return bufView;
    };

    const deriveChallenge = (code: any) => { 
        return new Promise(function (resolve, reject) {
            crypto.subtle.digest('SHA-256', textEncodeLite(code)).then(function (buffer) {
                return resolve(urlSafe(new Uint8Array(buffer)));
            }, function (error) { return reject(error); });
        });
    }

    const navToPing = () => { 
        const challenge = code_challenge;  
        const newPingURL = "https://" + pingURL + ".shell.com/as/authorization.oauth2?redirect_uri="+ process.env.REACT_APP_SSO_APP_REDIRECT_URL +"&client_id=" + 
        clientId + "&response_type=code&scope=email&audience=API_AUDIENCE"
        + "&access_type=offline" + "&code_challenge=" + challenge + "&code_challenge_method=S256"; 
        localStorage.setItem("verifier", codeVerifier); 
        window.location.href = newPingURL;
    }

    const setupCodeVerifier = () => {
        codeVerifier = generateRandom(43);
        const challenge = deriveChallenge(codeVerifier).catch(function (error) { 
            return undefined;
        });

        return challenge.then(function (result) {
            if (result) {
                code_challenge = result;
                navToPing();
            }
        }); 
    };

    const getURL = async () => {
        await setupCodeVerifier(); 
    }

    useEffect(() => {
        const hours = 1;
        const now = new Date().getTime() as any;
        const setupTime: any = localStorage.getItem('setupTime');
        if (setupTime == null) {
            window.localStorage.setItem('setupTime', now)
        } else {
            if(now - setupTime > hours*60*60*1000){
                window.localStorage.clear()
                window.localStorage.setItem('setupTime', now);        
            }
        }
        

        if(window.localStorage.getItem("email")){
            setUsername(window.localStorage.getItem("email"))
            setIsLoginSuccess(true);
            if (process.env.REACT_APP_USE_PINGID === 'false' ){
                navigate('/chat')
            }
        }else{
            if(getURLParameter("code", window.location.href)){
                getToken();
            }else{
                getURL()
            } 
        }
    }, [])

    const urlSafe = (buffer: any) => {
        const encoded = base64.fromByteArray(new Uint8Array(buffer));
        return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }

    return (
        (process.env.REACT_APP_USE_PINGID === 'true') 
        ?
            <div>
                {isLoginSuccess && <ChatStartPage />}
                {
                    showLoginError && 
                    <p className={classNames.loginError}>
                        Sorry, user is not authorised to use Shell-e.<br/>
                        If you require access, please contact the Shell-e team.
                    </p>
                }
            </div>
        :
            <div>
                {isLoginSuccess && <App />}
            </div>
    ); 
}

export default withRouter(Login);